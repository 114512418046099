<template>
    <div class="grid tw-h-full">
        <v-list color="blue-grey lighten-5" dense>
            <v-subheader>Personal Settings</v-subheader>
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
                exact
                :to="{name: item.children ? item.children[0].name : item.name}"
            >
                <v-list-item-icon>
                    <v-icon dense>{{ item.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.meta.label }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <router-view />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    
    export default {
        data() {
            return {
                drawer: true,
            }
        },
        computed: {
            ...mapGetters(['authenticated', 'me']),
            items() {
                return this.$router.options.routes.find((route) => route.name === 'settings').children.filter((route) => {
                    if (route.meta.location !== 'sidebar' || route.meta.group !== this.$route.meta.group) {
                        return false;
                    }
                    
                    return true;
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .grid {
        display: grid;
        grid-template-columns: 250px calc(100% - 250px);
    }
</style>
